import Grid from "@mui/material/Grid"
import React from "react"
import AirbnbEmbed from "../components/AirbnbEmbed"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const roomData = [
  { name: "Tahquitz Rock Room", id: 35291735 },
  { name: "Lily Rock Room", id: 39796616 },
  { name: "Wolf's Lair", id: 39128439 },
]

const Rooms = () => (
  <Layout>
    <SEO title="Our Rooms" />
    <Grid container spacing={0}>
      {roomData.map((room) => (
        <Grid item sm={6} xs={12} key={room.id}>
          <AirbnbEmbed roomID={room.id} />
        </Grid>
      ))}
    </Grid>
  </Layout>
)

export default Rooms
