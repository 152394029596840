import React, { useEffect } from "react"

const AirbnbEmbed = (props: { roomID: number }) => {
  // useEffect(() => {
  //   const script = document.createElement("script")
  //   script.src = "https://www.airbnb.com/embeddable/airbnb_jssdk"
  //   document.getElementsByClassName("airbnb-embed-frame")[0].appendChild(script)
  // }, [])

  return (
    <section className="airbnbContainer">
      <div
        className="airbnb-embed-frame"
        data-id={props.roomID}
        data-view="home"
        style={{
          overflow: "hidden",
          paddingTop: "98.25%",
          position: "relative",
        }}
      >
        <iframe
          style={{
            border: 0,
            height: "100%",
            left: 0,
            position: "absolute",
            top: 0,
            width: "100%",
          }}
          src={`https://www.airbnb.com/embeddable/home?id=${props.roomID}`}
        />
        {/* <a
          href={`https://www.airbnb.com/rooms/${props.roomID}?s=66&amp;shared_item_type=1&amp;virality_entry_point=1&amp;sharer_id=181864730&amp;source=embed_widget`}
        >
          View On Airbnb
        </a>
        <a
          href={`https://www.airbnb.com/rooms/${props.roomID}?s=66&amp;shared_item_type=1&amp;virality_entry_point=1&amp;sharer_id=181864730&amp;source=embed_widget`}
          rel="nofollow"
        >
          Tahquitz Rock Room
        </a> */}
      </div>
    </section>
  )
}

export default AirbnbEmbed
